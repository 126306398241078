// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-csp-js": () => import("./../../../src/pages/csp.js" /* webpackChunkName: "component---src-pages-csp-js" */),
  "component---src-pages-euff-js": () => import("./../../../src/pages/euff.js" /* webpackChunkName: "component---src-pages-euff-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interlay-js": () => import("./../../../src/pages/interlay.js" /* webpackChunkName: "component---src-pages-interlay-js" */),
  "component---src-pages-jjn-js": () => import("./../../../src/pages/jjn.js" /* webpackChunkName: "component---src-pages-jjn-js" */),
  "component---src-pages-kyc-js": () => import("./../../../src/pages/kyc.js" /* webpackChunkName: "component---src-pages-kyc-js" */)
}

